
.a-sections{
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}
.sectionone{
    margin-top: 30px;
}

.section1-image {
width: 100%;
height: auto;
object-fit: cover; 
}

  .carousel{
    z-index: 1;
  }

.section1-image-name{
    position: relative;
}

.section1-name{
    position:absolute;
    top: 5px;
    left: 5px;
    padding: 10px 20px;
    z-index: 1;
    color: #000;
    font-size: 25px;
    font-weight: 700;
    margin: 0;
}

.section__discover-btn {
    position: absolute;
    top: 50px;
    left: 25px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 7px 2px;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    color: #000;
    transition: all 0.2s ease;
    overflow: hidden; /* Ensure the ::before element stays within the button */
}

.section__discover-btn::before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 0; /* Initial height */
    background-color: #000;
    z-index: 0; /* Place behind the text */
    transition: height 0.2s ease; /* Smooth transition for height */
}

.section__discover-btn:hover {
    color: #fff; /* Change text color on hover */
}

.section__discover-btn:hover::before {
    height: 100%; /* Expand height on hover */
}

/* Ensure text stays above ::before */
.section__discover-btn span {
    position: relative;
    z-index: 1;
}


@media screen and (max-width: 768px) {
    .section1-image {
        height: 220px;
    }
    .sectionone{
        margin-top: 10px;
    }
    .section1-name{
        position:absolute;
        /* top: 0;
        left:0; */
        z-index: 1;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }
}
