.productPage{
margin-left: 120px;
margin-right:120px;
}

.filter-overlay {
position: fixed;
top: 0;
right: -100%;
width: 100%;
height: 100%;
background: linear-gradient(to right, rgba(0, 0, 0, 0.719) 50%, white 50%);
z-index: 1000;
transition: right 0.3s ease-in-out;
overflow: hidden;
}

.filter-overlay.show {
right: 0;
}

.filter-container {
position: absolute;
top: 0;
left: 50%;
width: 50%;
height: 100%;
overflow-y: auto;
}

.no-scroll {
overflow: hidden;
}

.subcategory-count{
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 15px;
align-items: baseline;
margin-top: 0;
}

.products-subcategory {
font-size: 22px;
font-weight: 700;
}

.products-count{
font-weight: 400;
font-size: 14px;
margin-left: 15px;
}

.products-filter-button{
background: none;
border: none;
border-right: 2px solid black;
padding: 14px;
font-weight: 900;
font-size: 12px;
cursor: pointer;
}

.sort-label{
padding: 14px;
font-weight: 900;
font-size: 12px;
}

.sort-select, .sort-select:focus{
border: none;
outline: none;
font-size: 12px;
}

.product-container {
display: flex;
flex-direction: row ;
flex-wrap: wrap;
gap: 20px;
margin-top: 30px;
}

.products-img {
width: 100%;
object-fit: cover;
aspect-ratio: 9/16;
}

.products-websitename-title {
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* line-height: 20px; */
    min-height: 40px;       /* height is 2x line-height, so two lines will display */
    overflow: hidden;  /* prevents extra lines from being visible */
    /* width: 400px; */
}

@media (max-width: 750px) {
    .products-websitename-title{
        min-height: 40px;       /* height is 2x line-height, so two lines will display */
    }
  }

.products-websitename {
font-weight: 700;
margin-top: 5px;
margin-bottom: 5px;
/* width: 400px; */
}

.products-price{
margin-top: 0;
font-weight: 450;
}
